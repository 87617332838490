import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'

import SEO from '../components/seo'
import Layout from '../components/layout'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  LinkedinShareButton,
} from 'react-share'
import { Facebook, Twitter, Mail, Linkedin } from 'react-feather'

import HeroRatingStars from '../components/hero-rating-stars'

import { ServingCitiesText } from '../components/ServingCitiesText'

import Breadcrumbs from '../components/breadcrumbs'

import { Star } from 'react-feather'
import { TOTAL_REVIEW_COUNT, REVIEW_AVG } from '../consts'

import daysFromToday from '../helpers/daysFromToday'

const ContactCard = styled.div`
  top: 30px !important;
  background: rgb(249 249 249);
`

const BlogPostLayout = styled.div`
  background: #fff;
  .articleBody {
    max-width: 900px;
    margin: 0 auto;
    padding: 1em;
  }
`

const Header = styled.div`
  text-align: left;
  margin: 0 auto;
  margin-bottom: 1em;
  border-bottom: 1px solid #eaeaea;
  h1 {
    font-size: calc(1.25vw + 1.25em);
    line-height: 1.15;
    font-weight: 800;
    color: #000;
  }
  border-bottom: 1px solid #f1f1f1;

  background: #f7f7f7;
`

const BlogPostImage = styled.div`
  img {
    max-width: 900px;
    margn: 0 auto;
    text-align: center;
    border-radius: 0;
    margin-bottom: 1em;
    box-shadow: none !important;
  }
`
const ShareThisPost = styled.div`
  p {
    font-weight: bold;
    margin: 0;
  }
  display: flex;
  align-items: center;
  .SocialMediaShareButton {
    display: flex;
    padding: 0 9px;
  }
  svg {
    stroke: none;
    fill: #888;
    cursor: pointer;
  }
  .stroke-white {
    stroke: white;
  }
`

class CareersPostTemplate extends Component {
  componentDidMount() {
    this.insertFormScript();
  }

  insertFormScript() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.text = `
      (function() {
        try {
          var f = document.createElement("iframe");
          f.src = 'https://forms.zohopublic.com/usqualityconstruction/form/JobPost/formperma/sF7C70IwA6av2Sh7050iAr2d0ZCoi-M_wjwzuV5wK2k?zf_rszfm=1';
          f.style.border = "none";
          f.style.height = "833px";
          f.style.width = "90%";
          f.style.transition = "all 0.5s ease";
          f.setAttribute("aria-label", 'Apply\x20Now\x21');

          var d = document.getElementById("zf_div_sF7C70IwA6av2Sh7050iAr2d0ZCoi-M_wjwzuV5wK2k");
          d.appendChild(f);
          window.addEventListener('message', function () {
            var evntData = event.data;
            if (evntData && evntData.constructor == String) {
              var zf_ifrm_data = evntData.split("|");
              if (zf_ifrm_data.length == 2 || zf_ifrm_data.length == 3) {
                var zf_perma = zf_ifrm_data[0];
                var zf_ifrm_ht_nw = (parseInt(zf_ifrm_data[1], 10) + 15) + "px";
                var iframe = document.getElementById("zf_div_sF7C70IwA6av2Sh7050iAr2d0ZCoi-M_wjwzuV5wK2k").getElementsByTagName("iframe")[0];
                if ((iframe.src).indexOf('formperma') > 0 && (iframe.src).indexOf(zf_perma) > 0) {
                  var prevIframeHeight = iframe.style.height;
                  var zf_tout = false;
                  if (zf_ifrm_data.length == 3) {
                    iframe.scrollIntoView();
                    zf_tout = true;
                  }
                  if (prevIframeHeight != zf_ifrm_ht_nw) {
                    if (zf_tout) {
                      setTimeout(function () {
                        iframe.style.height = zf_ifrm_ht_nw;
                      }, 500);
                    } else {
                      iframe.style.height = zf_ifrm_ht_nw;
                    }
                  }
                }
              }
            }
          }, false);
        } catch (e) { }
      })();
    `;
    document.body.appendChild(script);
  }

  render() {
    const post = this.props.data.contentfulCareers;
    const siteurl = this.props.data.site.siteMetadata.siteUrl;
    const { relatedCareers } = this.props.data;

    return (
      <Layout pageProps={this.props}>
        <div className="bg-white border-b border-gray-100">
          <nav
            className="flex container mx-auto px-4 py-3"
            aria-label="Breadcrumb"
          >
            <Breadcrumbs
              links={[
                { to: '/', label: 'Home' },
                { to: '/careers/', label: 'Careers' },
                {
                  to: this.props.location.pathname,
                  label: post.name,
                },
              ]}
            />
          </nav>
        </div>
        <SEO
          title={post.metaTitle ? post.metaTitle : post.name}
          description={post.metaDescription ? post.metaDescription : post.name}
          img={
            post.image &&
            post.image.gatsbyImageData &&
            post.image.gatsbyImageData.images &&
            post.image.gatsbyImageData.images.fallback &&
            post.image.gatsbyImageData.images.fallback.src
          }
          twitterCardContent={'summary_large_image'}
        />
        <BlogPostLayout className="relative">
          <article itemScope itemType="https://schema.org/Article">
            <div className="container pt-4">
              <h1 className="text-2xl md:text-4xl font-bold">{post.seoH1Title}</h1>
              <div className="grid md:grid-cols-2 mt-4 pb-4 gap-6">
                <div className="left">
                  <GatsbyImage
                    image={getImage(post.image)}
                    alt={post.name}
                    style={{
                      height: '400px',
                      objectFit: 'cover',
                      boxShadow: '1px 2px 8px 1px hsla(0,0%,0%,0)',
                    }}
                    className="blogPostImage border-radius shadow-md"
                  />
                  {post.body && (
                    <div className="mt-4" dangerouslySetInnerHTML={{ __html: post.body.body }} />
                  )}
                  {post.customCode && post.customCode.customCode && (
                    <div className="custom-code mt-4" dangerouslySetInnerHTML={{ __html: post.customCode.customCode }} />
                  )}
                </div>
                <div className="flex flex-col">
                  <div className="offer-card flex flex-col relative text-white border-radius p-6">
                    <GatsbyImage
                      image={getImage(post.image)}
                      className="image-overlay border-radius shadow-md"
                    />
                    <div className="relative z-10">
                      <div id="zf_div_sF7C70IwA6av2Sh7050iAr2d0ZCoi-M_wjwzuV5wK2k" style={{ textAlign: 'center' }}></div>
                    </div>
                  </div>
                </div>
              </div>
              <ShareThisPost className="bg-white px-6 justify-content-end p-2">
                <p className="text-base tracking-widest uppercase">Share —{' '}</p>
                <FacebookShareButton
                  url={`${siteurl}/careers/${post.slug}`}
                  quote={post.name}
                  hashtag={'#usqualityconstruction'}
                  className="bg-white hover:bg-gray-200 cursor-pointer flex h-8 items-center p-1 rounded-full w-8 mr-1"
                >
                  <Facebook size={20} />
                </FacebookShareButton>

                <TwitterShareButton
                  url={`${siteurl}/careers/${post.slug}`}
                  title={post.name}
                  hashtags={post.tags}
                  className="bg-white hover:bg-gray-200 cursor-pointer flex h-8 items-center p-1 rounded-full w-8 mr-1"
                >
                  <Twitter size={20} />
                </TwitterShareButton>
                <LinkedinShareButton
                  url={`${siteurl}/careers/${post.slug}`}
                  title={post.name}
                  quote={post.name}
                  hashtags={post.tags}
                  className="bg-white hover:bg-gray-200 cursor-pointer flex h-8 items-center p-1 rounded-full w-8 mr-1"
                >
                  <Linkedin size={20} />
                </LinkedinShareButton>
                <EmailShareButton
                  subject={post.name}
                  url={`${siteurl}/careers/${post.slug}`}
                  openWindow={true}
                  body={'Check out this job posting: '}
                  className="bg-white hover:bg-gray-200 cursor-pointer flex h-8 items-center p-1 rounded-full w-8 mr-1"
                >
                  <Mail size={20} className="stroke-white" />
                </EmailShareButton>
              </ShareThisPost>
            </div>
          </article>

          {relatedCareers && (
            <div className="container mx-auto p-4 py-8">
              <h3>Other Job Postings</h3>
              <div className="grid grid-cols-2 gap-4 lg:grid-cols-4">
                {relatedCareers.edges.map((item, i) => (
                  <Link
                    key={i}
                    to={`/careers/${item.node.slug}/`}
                    className="bg-white border-radius shadow hover:shadow-lg"
                  >
                    <GatsbyImage
                      image={getImage(item.node.image)}
                      style={{ height: '150px' }}
                      alt={`${item.node.name}`}
                      className="border-radius-top"
                    />
                    <div className="p-3 py-4 pt-3">
                      <span className="font-semibold leading-tight text-gray-900 block">
                        {item.node.name}
                      </span>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          )}
        </BlogPostLayout>
      </Layout>
    )
  }
}

export default CareersPostTemplate

export const pageQuery = graphql`
query CareerPostBySlug($slug: String!) {
  site {
    siteMetadata {
      siteUrl
    }
  }
  contentfulCareers(slug: { eq: $slug }) {
    id
    name
    slug
    createdAt
    seoH1Title
    excerpt {
      excerpt
    }
    body {
      body
    }
    image {
      gatsbyImageData(width: 1500, quality: 70, placeholder: NONE)
    }
    metaTitle
    metaDescription
    customCode {
      customCode
    }
  }
  relatedCareers: allContentfulCareers(
    limit: 4
    sort: { order: DESC, fields: createdAt }
  ) {
    edges {
      node {
        id
        name
        slug
        image {
          gatsbyImageData(width: 1200, quality: 70, placeholder: NONE)
        }
      }
    }
  }
}
`
